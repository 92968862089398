import React from 'react';
import styled from 'styled-components';

import { Header, GlobalStyle, Footer, Container, Title, Text, Seo } from '..';
import underConstruction from '../../images/emoji/under-construction.png';

const Wrapper = styled.div`
  margin-top: 50px;
  padding-bottom: 86px;
  text-align: center;
`;

const Img = styled.img`
  margin-bottom: 15px;
`;

const UnderConstruction = ({ children, title, description, image }) => {
  return (
    <>
      <Seo title="Em construção" />
      <GlobalStyle />
      <Container backgroundColor="#F6F6F6" fluid marginBottom={78}>
        <Container>
          <Header />
          <Wrapper>
            <Img src={underConstruction} alt="Emoji placa de em construção" />
            <Title as="h3" size={28} weight={500} marginBottom={18}>
              Esse conteúdo ainda não está pronto
            </Title>
            <Text size={18}>
              Mas estou trabalhando para ele ficar disponível logo
            </Text>
          </Wrapper>
        </Container>
      </Container>
      <Container>
        <Footer />
      </Container>
    </>
  );
};

export default UnderConstruction;
